import React, { useState, useEffect } from 'react';
import './ImageSlider.css'; // Import the corresponding CSS file for styling

import image1 from '../../Assets/Sliding/first.jpg';
import image2 from '../../Assets/Sliding/second.jpg';
import image3 from '../../Assets/Sliding/third.jpg';

const ImageSlider = () => {
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const imageDelay = 3000;

  useEffect(() => {
    setImages([image1, image2, image3]);
  }, []);

  const moveImages = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  useEffect(() => {
    const interval = setInterval(moveImages, imageDelay);
    return () => clearInterval(interval);
  }, [images.length, imageDelay]);

  return (
    <div className="image-slider-container">
      <div
        className="image-slider"
        style={{
          transform: `translateX(${-currentIndex * 100}%)`,
          transition: 'transform 1s ease', // Change transition effect to 'ease'
        }}
      >
        {images.map((image, index) => (
          <img key={index} src={image} alt={`Slide ${index + 1}`} />
        ))}
        {/* Clone the first image and append it to the end for continuous transition */}
        <img key={images.length} src={images[0]} alt={`Slide ${images.length + 1}`} />
      </div>
    </div>
  );
};

export default ImageSlider;
