import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import AC from '../../Assets/Icon/air-conditioner.png'
import Menu from '../../Assets/Icon/menu.png'
import Ambeince from '../../Assets/Icon/wedding-dinner.png'
import Staff from '../../Assets/Icon/motivation.png'
import Capacity from '../../Assets/Icon/users-group.png'
import Restaurant from '../../Assets/Icon/food.png'
import Location from '../../Assets/Icon/location.png'
import './Icon.css'

const Icon = () => {
  
  return (
    <div className='container'>
      <div className='row'>
        <div className="icon1 col-md-2 mb-4">
          <div className='iconbox'>
            <div className='iconbox-icon-wrap'>
              <span className='iconbox-icon-container'>
                <img className='image' src={Capacity} alt='capacity' />
              </span>
            </div>
            <div className='content1'>
              <p>
                <strong><span className='name1'> Banquet </span> <span className='value1'>Capacity : 40 Persons</span></strong>
              </p>
            </div>
          </div>
        </div>
        <div className="icon2 col-md-2 mb-4">
          <div className='iconbox'>
            <div className='iconbox-icon-wrap'>
              <span className='iconbox-icon-container'>
                <img className='image' src={Restaurant} alt='capacity' />
              </span>
            </div>
            <div className='content2'>
              <p>
                <strong><span className='name2'>Restaurant</span> <span className='value2'>Capacity : 90 Persons</span></strong>
              </p>
            </div>
          </div>
        </div>
        <div className="icon3 col-md-2 mb-4">
          <div className='iconbox'>
            <div className='iconbox-icon-wrap'>
              <span className='iconbox-icon-container'>
                <img className='image' src={AC} alt='AC' />
              </span>
            </div>
            <div className='content3'>
              <p>
                <strong>Centralised AC</strong>
              </p>
            </div>
          </div>
        </div>
        <div className="icon4 col-md-2 mb-4">
          <div className='iconbox'>
            <div className='iconbox-icon-wrap'>
              <span className='iconbox-icon-container'>
                <img className='image' src={Staff} alt='staff' />
              </span>
            </div>
            <div className='content4'>
              <p>
                <strong>Nice Staff</strong>
              </p>
            </div>
          </div>
        </div>
        <div className="icon5 col-md-2 mb-4">
          <div className='iconbox'>
            <div className='iconbox-icon-wrap'>
              <span className='iconbox-icon-container'>
                <img className='image' src={Location} alt='AC' />
              </span>
            </div>
            <div className='content5'>
              <p>
                <strong>Perfect Location</strong>
              </p>
            </div>
          </div>
        </div>
        <div className="icon6 col-md-2 mb-4">
          <div className='iconbox'>
            <div className='iconbox-icon-wrap'>
              <span className='iconbox-icon-container'>
              <img className='image' src={Ambeince} alt='AC' />
              </span>
            </div>
            <div className='content6'>
              <p>
                <strong>Great Ambience</strong>
              </p>
            </div>
          </div>
        </div>
        <div className="icon7 col-md-2 mb-4">
          <div className='iconbox'>
            <div className='iconbox-icon-wrap'>
              <span className='iconbox-icon-container'>
              <img className='image' src={Menu} alt='AC' />
              </span>
            </div>
            <div className='content7'>
              <p>
                <strong>Choice Menu</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Icon;