import React from "react";
import "./Footer.css"
import logo from "../../Assets/Logo/Logo.png"
import bnq from "../../Assets/Menu/bnq.jpg"
import pocket from "../../Assets/Menu/rst.pdf"
import main from "../../Assets/Menu/main.pdf"
import '@fortawesome/fontawesome-svg-core/styles.css'; // Import the Font Awesome core styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';


const Footer=()=>
{
    return(
        <div className="container-backgroundimg">
            <div className="segement">
            <div className="first-segement">
                <img src={logo} alt="logo" className="logo" />
            </div>
            <div className="second-segement">

                <div className="contact-us">CONTACT US</div>
                <div className="address-des">1st Floor, Aasthan Complex, Ambavadi,
                 opp. Govt Polytechnic College, Ahmedabad, Gujarat 380015
                 <br />
                 <a className="contact-1" href='tel:+079-26309297'>+079-26309297</a>
                <br />
                <a className="contact-2" href='tel:+91-9429185612'>+91-9429185612</a>
                 </div>
                
            </div>
            <div className="third-segement">
                <div className="useful-link">USEFUL LINKS</div>
                <a href={bnq} target="_blank">Choice Menu</a><br />

                <a href={main} target="_blank">Main Menu</a><br />

                <a href={pocket} target="_blank">Pocket Menu</a><br />

                <a href="https://www.google.com/maps/place/City+Corner+Restaurant/@23.0249774,72.5446869,17z/data=!3m1!4b1!4m6!3m5!1s0x395e84dc3fffffff:0x9404a1f94849828e!8m2!3d23.0249725!4d72.5472618!16s%2Fg%2F1tj5p3ph?entry=ttu" 
                target="_blank">Rate us on Google</a>
            </div>
            <div className="fourth-segement">
                <div className="touch">KEEP IN TOUCH</div>
                <a href="https://wa.me/+919429185612" target="_blank" class="whatsapp-link-2" >
                <FontAwesomeIcon icon={faWhatsapp} className="whatsapp-logo-2" />
                </a>
                <a href="https://www.instagram.com/city_corner_429/" target="_blank" class="instagram-link" >
                <FontAwesomeIcon icon={faInstagram} className="instagram-logo" />
                </a>
            </div>
            </div>
            <hr className="line"/>
            <div className="maker">
            <div className="rights">© 2024-2025 City Corner Restaurant & Banquet. All Rights Reserved.</div>
            <div className="maker-raza">Hand Crafted By - <a className="text" href ="https://razakharodiya.github.io/Portfolio/" 
            target="_blank">Mohammad Raza Kharodiya</a></div>
            </div>
        </div>
    )
}
export default Footer