import React from "react";
import "./Feature.css";
import image1 from '../../Assets/Feature/First.jpg';
import image2 from '../../Assets/Feature/second.png';
import image3 from '../../Assets/Feature/third.png';
import wedding from '../../Assets/Feature/01.png';
import bithday from '../../Assets/Feature/02.png';
import get from '../../Assets/Feature/03.png';
import ring from '../../Assets/Feature/04.png';
import corporate from '../../Assets/Feature/05.png';
import other from '../../Assets/Feature/06.png';

const Feature = () => {
  const imageTextData = [
    { imageSrc: wedding, text: 'Wedding Reception' },
    { imageSrc: bithday, text: 'Birthday Celebration' },
    { imageSrc: get, text: 'Get Together' },
    { imageSrc: ring, text: 'Ring Ceremony' },
    { imageSrc: corporate, text: 'Corporate Event' },
    { imageSrc: other, text: 'Other Event' },
  ];

  return (
    <div>
      <div className="container">
        <div className="since">
          <header className="fancy">
            <h3 className="since_year"><em>Since 1998</em></h3>
            <h2 className="unique">Authentic & Unique Experience.</h2>
          </header>
        </div>
      </div>
      <div className="part">
        <div className="left-part">
          <div className="image-segment">
            <img src={image1} className="first-img" alt="first-img" />
            <img src={image2} className="second-img" alt="second-img" />
            <img src={image3} className="third-img" alt="third-img" />
          </div>
        </div>
        <div className="right-part">
          <header className="right-fancy">
            <h5 className="fancy-title">Let's</h5>
            <h2 className="fancy-description">Make Your occasion An Event To Remember!</h2>
            <p className="description">Our top-notch Restaurant & Banquet facilities render utmost convenience,
              professionalism and perfection to the event. Step in for an experience that's
              memorable and perfect in every way.</p>
            <hr />
          </header>
          <div className="image-text-container">
            {imageTextData.map((pair, index) => (
              <div key={index} className="image-text-pair">
                <p className="texts">
                <img src={pair.imageSrc} alt={`Image ${index + 1}`} />
                <e className="space">{pair.text}</e></p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
