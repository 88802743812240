// Contact.js
import React from "react";
import "./ContactUs.css";
import Cimage from "../../Assets/ContactUs/first.png";

const Contact = () => {
  return (
    <div id="FindNow">
    <div className="title-c">Contact Us</div>
    <div className="container-contact-c">
      <div className="part-c">
        <div className="left-part-c">
          <img src={Cimage} alt="no image found" className="C-image-c" />
          <div className="image-text-c">

            <div className="header-text-c">Get in touch</div>

            <div className="address-title-c">Address:
            <div className="space-c"></div>
            <div className="address-description-c">1st Floor, Aasthan Complex, Ambavadi, 
            opp. Govt Polytechnic College, Ahmedabad, Gujarat 380015</div>
            </div>

            <div className="contact-c">Contact Number:
            <div className="space-c"></div>
            <a className="contact-1-c" href='tel:+079-26309297'>079-26309297</a>
            <br />
            <a className="contact-2-c" href='tel:+91-9429185612'>91-9429185612</a>
            </div>

            <div className="timing-c">Timing:
            <div className="space-c"></div>
              <div className="timing-1-c">11AM-3:30PM</div>
              <div className="timing-2-c">6:30PM-11:30PM</div>
            </div>

          </div>
        </div>
        <div className="right-part-c">
        <p>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d32032.061356642895!2d72.5587631032952!
          3d23.035106981027504!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e84dc3fffffff%3A0x9404a1f94849828e!
          2sCity%20Corner%20Restaurant!5e0!3m2!1sen!2sin!4v1706589687340!5m2!1sen!2sin"
          className="embedded-map-c" 
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          alt="location not found"
        >
        </iframe>
      </p>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Contact;
