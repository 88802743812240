import React, { useState } from 'react';
import './App.css';
import Home from "./components/Homepage/Home";
import About from "./components/About/About";
import Icon from "./components/Icon/Icon";
import Feature from './components/Feature/Feature';
import Contact from "./components/ContactUs/ContactUs";
import Menu from "./components/Menu/Menu";
import Footer from './components/Footer/Footer';
import Order from "./components/Order/Order";
import Testimonial from './components/Testimonial/Testimoinal';

function App() {
  const [orderPageVisible, setOrderPageVisible] = useState(false);

  return (
    <div className="App">
      <Home />
      <About />
      <Icon />
      <Feature />
      <Testimonial />
      <Menu />
      <Contact />
      <Footer />
      {orderPageVisible && <Order />}
    </div>
  );
}

export default App;
