import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'; // Import the icons you want to use
import "./Testimonial.css";

const Testimonial = () => {
    const testimonials = [
        {
            name: "Feil Bhatt",
            review: "City Corner, the Punjabi restaurant, garners rave reviews for its outstanding food and impeccable service.Offering authentic Punjabi cuisine, the restaurant creates a warm and inviting atmosphere that keeps customers coming back for more. Highly recommended for those in search of delectable Punjabi dishes."
        },
        {
            name: "Neeraj Tuli",
            review: "One of very famous and favorite restaurant for vegetarian food lovers... has been offering wide variety of almost all cuisines.. Indian, Chinese, Mexican... also has place for bigger gathering.. and buffet meals also available... one of the favorite dishes is pasta in white sauce( photo attached)."
        },
        {
            name:"raj dholia",
            review:"When it comes to City Corner. It has a special place in my heart. The restaurant which I visit since childhood. One of the best punjabi place ik Ahmedabad. So consistent, so yummy, just tasting like a wow. Must try palce"
        },
        {
            name:"Apeksha prjapati",
            review:"City corner restaurant serves very tasty food. We have tried Chinese food, which was awesome in every aspect, Service and staff compatibility very coordinated, everyone should definitely visit once for memorable food."
        },
        {
            name:'Nishit Bhatt',
            review:"Visited yesterday after long 4-5 yrs, taste is still the same as it use to be during my college days. 🙃 #MustVisit"  
        },
        {
            name:"Pooja Acharya",
            review:"Such a great place must say !!! Loved the food and the quantity of food was amazing !!! The staff was good as well !! Must visit 👍🏻👍🏻"
        }

        // Add more testimonials as needed
    ];

    const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);

    const handleNext = () => {
        setCurrentTestimonialIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    };

    const handlePrev = () => {
        setCurrentTestimonialIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
    };

    return (
        <div className="testimonial">
            <div className="title">Happy customers</div>
            <div className="controls">
                <button onClick={handlePrev}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </button>
                <div className="testimonial-content">
                    <div className="testimonial-item">
                        <p>{testimonials[currentTestimonialIndex].review}</p>
                        <br />
                        <p><strong>- {testimonials[currentTestimonialIndex].name}</strong></p>
                        <p>𝘎𝘰𝘰𝘨𝘭𝘦 𝘉𝘶𝘴𝘪𝘯𝘦𝘴𝘴 𝘳𝘦𝘷𝘪𝘦𝘸</p>
                    </div>
                </div>
                <button onClick={handleNext}>
                    <FontAwesomeIcon icon={faChevronRight} />
                </button>
            </div>
            <a href="https://www.google.com/maps/place/City+Corner+Restaurant/@23.0249725,72.5472618,17z/data=!4m16!1m9!3m8!1s0x395e84dc3fffffff:0x9404a1f94849828e!2sCity+Corner+Restaurant!8m2!3d23.0249725!4d72.5472618!9m1!1b1!16s%2Fg%2F1tj5p3ph!3m5!1s0x395e84dc3fffffff:0x9404a1f94849828e!8m2!3d23.0249725!4d72.5472618!16s%2Fg%2F1tj5p3ph?entry=ttu" 
                target="_blank">Write Reviews</a>
        </div>
    );
}

export default Testimonial;
