import React from "react";
import { Helmet } from "react-helmet";
import ImageSlider from "./ImageSlider";
import "./Home.css";
import '@fortawesome/fontawesome-svg-core/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Header from "../Header/Header";

const Home = () => {
  return (
    <div className="Home" id="Home">
      <Helmet>
        <title>City Corner Restaurant & Banquet</title>
        <meta name="description" content="Enjoy delicious food and elegant banquet services at City Corner. We offer banquet halls, catering, and event hosting services in Ahmedabad, Gujarat." />
        <meta name="keywords" content="City Corner, City Corner Restaurant,city corner banquet,city corner ahmedabad,city corner ambawadi,city,coner, Banquet, Restaurant, Dining, Events, Catering, Banquet Halls, Ahmedabad, Gujarat, Seminar Halls, Product Launch venues, Birthday Party venues, Corporate Party venues, Engagement venues, Meeting venues, Wedding venues, Conference venues, Wedding Reception venues, Baby Shower venues, Get together venues, Wedding Anniversary venues, First Birthday Party venues, Exhibition venues, Brand Promotion venues, Group Dining venues, Family Function venues, Dealers Meet venues, Ring Ceremony venues, Children Party venues, Corporate Training venues, Family Get together venues, Kids Birthday Party venues" />
        <meta name="author" content="City Corner Restaurant & Banquet" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="City Corner Restaurant & Banquet" />
        <meta property="og:description" content="Enjoy delicious food and elegant banquet services at City Corner. We offer banquet halls, catering, and event hosting services in Ahmedabad, Gujarat." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.citycornerrestaurant.com/" />
        <meta property="og:image" content="logo512.png" />
      </Helmet>
      
      <div className="home-container">
        <div className="imgclass">
          <ImageSlider />
          <div className="inquiry">
            <a href='tel:+91-9429185612'>MAKE AN INQUIRY</a>
          </div>
        </div>
        <div className="header">
          <Header />
        </div>
        <div className="background">
          <div className="centered-text">
            <h6>𝐏𝐫𝐢𝐦𝐞 𝐋𝐨𝐜𝐚𝐭𝐢𝐨𝐧/𝐄𝐱𝐜𝐞𝐩𝐭𝐢𝐨𝐧𝐚𝐥 𝐒𝐞𝐫𝐯𝐢𝐜𝐞</h6>
            <h1><strong>𝐏𝐞𝐫𝐟𝐞𝐜𝐭 𝐌𝐚𝐭𝐜𝐡 𝐟𝐨𝐫 𝐀𝐧𝐲 𝐒𝐢𝐭𝐮𝐚𝐭𝐢𝐨𝐧</strong></h1>
          </div>
          <div className="address">
            <p>1st Floor, Aasthan Complex, Ambavadi, opp. Govt Polytechnic College, Ahmedabad, Gujarat 380015</p>
          </div>
        </div>
      </div>
      <a href="https://wa.me/+919429185612" target="_blank" className="whatsapp-link">
        <FontAwesomeIcon icon={faWhatsapp} className="whatsapp-logo" />
      </a>
    </div>
  );
}; 

export default Home;
