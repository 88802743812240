import React, { useState, useEffect } from "react";
import "./Header.css";
import { Link } from "react-scroll";
import companyLogo from "../../Assets/Logo/Logo.png";
import { GiHamburgerMenu } from "react-icons/gi";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth > 998) {
        setMenuOpen(false); // Close the menu when the screen width is greater than 998px
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className={`header ${menuOpen ? "menu-open" : ""}`} id="header">
      <div className="header-container">
        <div className="logo-container">
          <img src={companyLogo} alt="Company Logo" className="company-logo" />
        </div>

        <div className="mobile-menu-container">
          <div className="menu-icon" onClick={handleMenuToggle}>
            <GiHamburgerMenu />
          </div>

          {windowWidth <= 998 && menuOpen && (
            <ul className="header-menu-mobile">
              <li>
              <a className="navLink"
                activeClass="active"
                to="Home"
                spy={true}
                smooth={true}
                href="/"
                onClick={() => setMenuOpen(false)}
              >
                Home
              </a>
            </li>
            <li>
              <Link
                activeClass="active"
                to="About"
                spy={true}
                smooth={true}
                onClick={() => setMenuOpen(false)}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="FindNow"
                spy={true}
                smooth={true}
                onClick={() => setMenuOpen(false)}
              >
                ContactUs
              </Link>
            </li>
            <li>
             <Link
                activeClass="active"
                to="Order"
                spy={true}
                smooth={true}
                onClick={() => setMenuOpen(false)}
              >
                OrderFood
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="Menu"
                spy={true}
                smooth={true}
                onClick={() => setMenuOpen(false)}
              >
                Menu
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="FindNow"
                spy={true}
                smooth={true}
                onClick={() => setMenuOpen(false)}
              >
                FindNow
              </Link>
            </li>
            </ul>
          )}
        </div>

        {windowWidth > 998 && (
          <ul className={`header-menu ${menuOpen ? "open" : ""}`}>
            <div className="leftpart">
            <li>
              <a className="navLink"
                activeClass="active"
                to="Home"
                spy={true}
                smooth={true}
                href="/"
                onClick={() => setMenuOpen(false)}
              >
                Home
              </a>
            </li>
            <li>
              <Link
                activeClass="active"
                to="About"
                spy={true}
                smooth={true}
                onClick={() => setMenuOpen(false)}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="FindNow"
                spy={true}
                smooth={true}
                onClick={() => setMenuOpen(false)}
              >
                ContactUs
              </Link>
            </li>
          </div>
          <div className="rightpart"></div>
            <li>
              <Link
                activeClass="active"
                to="Order"
                spy={true}
                smooth={true}
                onClick={() => setMenuOpen(false)}
              >
                OrderFood
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="Menu"
                spy={true}
                smooth={true}
                onClick={() => setMenuOpen(false)}
              >
                Menu
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="FindNow"
                spy={true}
                smooth={true}
                onClick={() => setMenuOpen(false)}
              >
                FindUs
              </Link>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default Header;
