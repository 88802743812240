import React, { useState, useEffect } from "react";
import "./Order.css";
import availableItems from "./availableItems";

const Order = () => {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [note, setNote] = useState("");
  const [address, setAddress] = useState("");
  const [items, setItems] = useState([{ quantity: "1", name: "", subItem: "", type: "Regular" }]);
  const [isOpen, setIsOpen] = useState(false);
  const [delivery, setDelivery] = useState(false);
  const [deliveryOptionText, setDeliveryOptionText] = useState(delivery ? "You choose Delivery option" : "You choose Take Away option");

  useEffect(() => {
    const currentTime = new Date();
    const openingHoursMorningStart = new Date();
    openingHoursMorningStart.setHours(11, 15, 0); // 11:30 AM
    const openingHoursMorningEnd = new Date();
    openingHoursMorningEnd.setHours(15, 15, 0); // 3:30 PM
    const openingHoursEveningStart = new Date();
    openingHoursEveningStart.setHours(18, 45, 0); // 6:30 PM
    const openingHoursEveningEnd = new Date();
    openingHoursEveningEnd.setHours(22, 45, 0); // 10:45 PM

    if (
      (currentTime >= openingHoursMorningStart && currentTime <= openingHoursMorningEnd) ||
      (currentTime >= openingHoursEveningStart && currentTime <= openingHoursEveningEnd)
    ) {
      setIsOpen(true);
    }
  }, []);

  const handleAddItem = () => {
    setItems([...items, { quantity: "1", name: "", subItem: "", type: "Regular" }]);
  };

  const handleItemChange = (index, field, value) => {
    const newItems = [...items];
    newItems[index][field] = value;
    setItems(newItems);
  };

  const handleSubItemChange = (index, value) => {
    const newItems = [...items];
    newItems[index]["subItem"] = value;
    setItems(newItems);
  };

  const handleTypeChange = (index, value) => {
    const newItems = [...items];
    newItems[index]["type"] = value;
    setItems(newItems);
  };

  const handleDeleteItem = (index) => {
    if (items.length === 1) return; // Ensure at least one item remains
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name || !phoneNumber || items.some((item) => !item.quantity || !item.name || !item.subItem)) {
      alert("Please fill in all required fields.");
      return;
    }
    const orderData = { name, phoneNumber, note, address, items };
    sendOrderToWhatsApp(orderData);
    console.log("Submitted:", orderData);
  };

  const sendOrderToWhatsApp = (orderData) => {
    const message = `
      Name: ${orderData.name}
      Phone Number: ${orderData.phoneNumber}
      Note: ${orderData.note}
      Address: ${orderData.address}
      Items: ${orderData.items
        .map(
          (item) => `
        Quantity: ${item.quantity},Item Name: ${item.subItem}, Type: ${item.type}`
        )
        .join("")}
    `;

    const encodedMessage = encodeURIComponent(message);
    const whatsappURL = `https://wa.me/${+919429185612}?text=${encodedMessage}`;
    window.open(whatsappURL, "_blank");
  };

  const handleTakeAway = () => {
    setDelivery(false);
    setDeliveryOptionText("You choose Take Away option");
  };

  const handleDelivery = () => {
    setDelivery(true);
    setDeliveryOptionText("You choose Delivery option");
  };

  if (!isOpen) {
    return (
      <div className="container-order">
        <div className="message">Restaurant timing is 11:15 AM to 3:15 PM and 6:45 PM to 10:45 PM for online ordering food.</div>
      </div>
    );
  }

  return (
    <div id="Order">
      <div className="title">𝓟𝓵𝓪𝓬𝓮 𝓨𝓸𝓾𝓻 𝓞𝓻𝓭𝓮𝓻</div>
      <div className="button-container">
        <button className={delivery ? "" : "selected"} onClick={handleTakeAway}>Take Away</button>
        <button className={delivery ? "selected" : ""} onClick={handleDelivery}>Delivery</button>
      </div>
      <div>
        {deliveryOptionText}
      </div>
      <div className="container-order">
        <form onSubmit={handleSubmit}>
          <label>Name <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Your Name" required /></label>
          <label>Phone Number <input type="phone" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} placeholder="Mobile Number" required /></label>
          {delivery && (
            <label>Address <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} placeholder="Delivery Address" required /></label>
          )}
          <label>Note <input type="text" value={note} onChange={(e) => setNote(e.target.value)} placeholder="Note for Food Preparation" /></label>
          {items.map((item, index) => (
            <div key={index} className="item">
              <label>Item Quantity <input type="number" min="1" value={item.quantity} onChange={(e) => handleItemChange(index, "quantity", e.target.value)} required /></label>
              <label>
                Item Name
                <select value={item.name} onChange={(e) => handleItemChange(index, "name", e.target.value)} required>
                  <option value="">Select an item</option>
                  {availableItems.map(({ name }) => (
                    <option key={name} value={name}>{name}</option>
                  ))}
                </select>
              </label>
              {item.name && (
                <label>
                  Sub Item
                  <select value={item.subItem} onChange={(e) => handleSubItemChange(index, e.target.value)} required>
                    <option value="">Select a sub-item</option>
                    {availableItems.find(({ name }) => name === item.name)?.subItems.map((subItem) => (
                      <option key={subItem} value={subItem}>{subItem}</option>
                    ))}
                  </select>
                </label>
              )}
              <label>
                Type:
                <select value={item.type} onChange={(e) => handleTypeChange(index, e.target.value)} required>
                  <option value="Regular">Regular</option>
                  <option value="Jain">Jain</option>
                  <option value="Swaminarayan">Swaminarayan</option>
                </select>
              </label>
              {index !== 0 && (
                <button type="button" onClick={() => handleDeleteItem(index)}>Delete</button>
              )}
            </div>
          ))}
          <button type="button" onClick={handleAddItem}>Add Item</button>
          <br />
          <br />
          <button className="place-btn" type="submit">Place Your Order</button>
        </form>
      </div>
    </div>
  );
};

export default Order;
