// About.js
import React from "react";
import biggerImage from "../../Assets/About/first.png";
import smallerImage from "../../Assets/About/first.png";
import "./About.css"; // Create About.css for styling if needed

const About = () => {
  return (
    <div className="About" id="About">
      <div className="title">
        <h1 className="about-welcome">Welcome to</h1> 
        <h1 className="about-title">City Corner Restaurant & Banquet</h1>
      </div>
      <div className="about-container">
        <div className="firstsegement">
          <p className="about-description">
          The <z className="strong"> City Corner</z> with its offer of daily specials,
          along with both variety & value of our patrons
          is most popular & favourable amongst multi cuisine
          taste.
          <br />
          <br/>
          A casual elegant fine dinning experience plus an quick
          business lunch & dinner, celebration of special occasions
          & events with an day or evening nightlife & culture of
          Ambawadi's surrounding.
          </p>
        </div>
        <div className="secondsegemnt">
          <div className="image-segment">
            <img
              src={biggerImage}
              alt="Bigger product display in our store"
              className="bigger-image"
            />
            <img
              src={smallerImage}
              alt="Smaller product showcasing unique features"
              className="smaller-image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
