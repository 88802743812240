import React, { useState } from "react";
import Order from "../Order/Order";  // Import the Order component
import "./Menu.css";
import Restaurant from "./../../Assets/Menu/rst.pdf";
import Banquet from "./../../Assets/Menu/bnq.jpg";
import Main from "./../../Assets/Menu/main.pdf"

const Menu = () => {
  const [showOrderForm, setShowOrderForm] = useState(false);

  const handleOrderButtonClick = () => {
    setShowOrderForm(true);
  };

  const handleOrderFormClose = () => {
    setShowOrderForm(false);
  };

  const handleButtonClick = (pdfUrl) => {
    window.open(pdfUrl, '_blank');
  };

  return (
    <div className="backgroundimg" id="Menu" >
      <div className="container" id="Order">
        <div className="title-1">Our special</div>
        <div className="title-2">Restaurant & Banquet Menu</div>
        <br />
        <button className="rst-btn" onClick={() => handleButtonClick(Main)}>Restaurant Menu</button><br />
        <button className="rst-btn" onClick={() => handleButtonClick(Banquet)}>Banquet Menu</button><br />

        {/* Conditionally render the Order component */}
        {showOrderForm ? (
          <div >
            <Order />
            <button className="close-btn" onClick={handleOrderFormClose}>Close Order Form</button>
            <div className="note">Terms and Conditions Apply.</div>
          </div>
        ) : (
          <button className="rst-btn rst-btn-space" onClick={handleOrderButtonClick}>Order Food</button>
        )}
      </div>
    </div>
  );
}

export default Menu;
