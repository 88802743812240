const availableItems = [
    { name: "APPETISERS", subItems: ["JAL JEERA WATER / FRESH LIME WATER",
                                        "JAL JEERA SODA / FRESH LIME SODA",
                                        "LEMON COOLER",
                                        "MINERAL WATER",
                                        "AERATED WATER (S.C.)",
                                        "BUTTER MILK",
                                        "MASALA BUTTER MILK",
                                        "SP PUNJABI LASSI"  ] },
    { name: "SOUPS", subItems: ["REG. CLEAR COUP",
                                    "CREAM OF YEG. COUP",
                                    "CREAM OF TOMATO COUP",
                                    "TOMATO CORN CHEESE COUP",
                                    "MINESTRONE COUP",
                                    "REG. HOT & YOUR SOUP",
                                    "REG. MANCHOW COUP",
                                    "YEG. SWEET CORN SOUP",
                                    "LEMON CORIANDER SOUP",
                                    "FRESH ONION GARLIC SOUP (FRY ONION WITH CREAM DRESSING-INDIAN COUP)",
                                    "TOMATO DHANIYA SHORBA (CHOPS TOMATO & INDIAN TASTE COUP)",
                                    "YEG. NOODLES COUP",] },
    { name: "RAITA & SALAP", subItems: ["COOL RAITA(BOONDI / PINEAPPLE/ REG. RAITA)",
                                        "PLAIN CURD",
                                        "GREEN SALAD",
                                        "RUSSIAN SALAD",
                                        "KACHUM BER SALAD",
                                        "ROASTED PAPAD ",
                                        "FRIED PAPAD ",
                                        "MASALA PAPAD",
                                        "MASALA CHEESE PAPAD ",
                                        "GREEN CHATNI",] },
    { name: "TANDOORI'S STARTERS", subItems: ["PANEER TIKKA DRY (10 PCS.)",
                                "PANEER HARIYALI TIKKA (10 PCS.)",
                                "PANEER LASANIYA TIKKA (10 PCS.)",
                                "PANEER ADRAKI TIKKA (10 PCS.)",
                                "HARA BHARA KABAB (10 PCS.)",
                                "PAPAD KABAB (8 to 10 PCS.)",
                                "MIX TANDOORI PLATTERS (12 PCS.)",] },
    { name: "ORIENTAL STARTERS", subItems: ["VEG. LOLLY POP (6 PCS.)",
                                    "VEG. SPRING ROLL (10 PCS.)",
                                    "BABY CORN MUSHROOM POTATO SALT & PEPPER",
                                    "PANEER CHILLY DRY / GRAVY",
                                    "VEG. MANCHURIAN DRY / GRAVY",
                                    "VEG. CRISPY",
                                    "CRISPY CRUMCHY TANGY BABY CORN",
                                    "CRISPY POTATO SHAVING'S ON FAIR",
                                    "SHISAM FINGER'S",
                                    "POTATO CHILLY DRY",] },
    { name: "CONTINENTAL FEAST", subItems: ["BAKED VEG. FLORENTINE",
                                "BAKED MACARONI / SPAGHETTI",
                                "BAKED MACARONI / SPAGHETTI P/A",
                                "BAKED VEG. AUGRATIN (P/A)",
                                "BAKED CORN CHILLY",
                                "BURMESE SPAGHETTI",] },
    { name: "HOT SiZZLERS", subItems: ["CITY CORNER SP. SIZZLER(A Combination Of Boiled Veg's, French Fries, Cutlets & Macaroni With Rich Cream Sauce.)",
                                "PANEER STEAK SIZZLER(A Combination Of Cottage Cheese Patty In Brawn Pepper Flavors Sauce. A Little SpicyWith Assorted Boiled Veg's, French Fries & Rice)",
                                "VEG. GRILLED SIZZLER(A Combination Of Stuffed Tomato, Capsicum, Macaroni With Rich Sauce, Boiled Veg,. French Fries & Cutlets In Tangy Sauce With A Bed Of Shredded Cabbage)",
                                "PANEER SHASLIK SIZZLER(Diced Of Paneer In Capsicum & Tomatoes In A Spicy Barbeved Shashlik Sauce)",
                                "CHINESE SIZZLER(A Combination Of Chinese Dishes In A Veg.Fried Rice, Hakka Noodles, Paneer Chilly,Veg. Manchurian With Soya Based Chinese Sauce),"] },
    { name: "SUBZ-E KHAAS", subItems: ["SP. CITY CORNER VEG.",
                                "VEG. JAIPURI / VEG. KOLHAPURI",
                                "VEG. MAKHANWALA",
                                "VEG. JWALAMUKHI",
                                "VEG. KADAI MASALA / VEG. HANDI MASALA",
                                "VEG. TAWA MASALA",
                                "CORN TOMATO BHARTA",
                                "CORN PALAK / CORN CAPSICUM MASALA",
                                "BABY CORN HARA MASALA",
                                "BABY CORN MASALA",
                                "BABY CORN MUSHROOM MASALA",
                                "VEG. KHADA MASALA",
                                "VEG. KHEEMA MASALA",
                                "VEG. DIWANI HANDI",
                                "VEG. GARLIC MASALA",
                                "VEG. JALFREZI / VEG. BALTI MASALA",
                                "VEG. NAWABI",
                                "VEG. MUGHLAI",
                                "VEG. SINGAPURI",
                                "NAVRATAN KORMA (SWEET)",
                                "VEG. HARA MASALA",
                                "VEG. HYDERABADI",
                                "MUSHROOM MUTTER MASALA / METHI M/S",
                                "METHI MUTTER MALAI MASALA",
                                "PALAK MAKAI LASSUNI TADKA",
                                "PALAK AAP KI PASAND (MUSHROOM/GREEN PEAS)",
                                "MIX VEGETABLE",
                                "DUM ALOO (PUNJABI/KASHMIRI)",
                                "JEERA ALOO",
                                "ALOO (MUTTER/PALAK)",
                                "CHANA MASALA",] },
    { name: "KAJU KI KHUBIYA", subItems: ["KHOYA KAJU (SWEET)",
                                "KADAI KAJU CURRY",
                                "KAJU BUTTER MASALA",
                                "KAJU PANEER MASALA",
                                "CHEESE BUTTER MASALA",
                                "CHEESE PANEER MASALA",] },
    { name: "PANEER KE ZAYKE", subItems: ["PANEER TIKKA LABABDAR",
                                "PANEER AMRITSARI",
                                "PANEER TIKKA MASALA",
                                "PANEER BUTTER MASALA",
                                "PANEER KADAI MASALA",
                                "PANEER HANDI MASALA",
                                "PANEER KHADA MASALA",
                                "PANEER TOOFANI",
                                "PANEER GARLIC MASALA",
                                "PANEER TAWA MASALA",
                                "PANEER LAZEEZ",
                                "PANEER PASANDA",
                                "PANEER PATIYALA",
                                "PANEER BHURJI",
                                "PANEER ADRAKI",
                                "PANEER SHAHI KORMA",
                                "BALTI DA PANEER",
                                "PANEER METHI MALAI",
                                "PANEER BABY CORN MASALA",
                                "PANEER PALAK / MUTTER",
                                "PANEER HARA MASALA",] },
    { name: "KOFTA KI DAWAT", subItems: ["KAJU KOFTA",
                                "CHEESE KOFTA",
                                "PANEER KOFTA",
                                "KADAI KOFTA",
                                "MALAI KOFTA (SWEET)",
                                "KASHMIRI KOFTA (SWEET)",
                                "NARGISI KOFTA",
                                "VEG. KOFTA",] },
    { name: "CHINESE(RICE & NOODLES)", subItems: ["VEG. AMERICAN CHOPSUEY",
                                "VEG. CHOWMEIN",
                                "VEG. CHOW CHOW",
                                "VEG. SINGAPURI NOODLES",
                                "VEG. HAKKA NOODLES",
                                "VEG. SCHEZWAN NOODLES",
                                "CHILLY GARLIC NOODLES",
                                "VEG. HONG KONG NOODLES",
                                "SCHEZWAN FRIED RICE",
                                "MANCHURIAN FRIED RICE",
                                "CHINESE BHEL",
                                "MUSHROOM FRIED RICE",
                                "NOODLES FRIED RICE",
                                "VEG. FRIED RICE",] },
    { name: "RICE", subItems: ["VEG. HYDERABADI BIRIYANI",
                                "VEG. HANDI BIRIYANI",
                                "VEG. BIRIYANI",
                                "CHEESE PULAO",
                                "VEG. PULAO",
                                "PEAS PULAO",
                                "KASHMIRI PULAO (SWEET)",
                                "SHAHJAHANI PULAO (SWEET)",
                                "JEERA RICE",
                                "PLAIN RICE",] },
    { name: "DAL", subItems: ["DAL MAKHANI",
                                "DAL TADKA (HANDI)",
                                "DAL PALAK",
                                "DAL FRY",
                                "DAL FRY (BUTTER)",] },
    { name: "TANDOORI DAWAT(ROTI)", subItems: ["KASHMIRI NAAN",
                                "CHEESE NAAN",
                                "CHEESE GARLIC NAAN",
                                "CHEESE CHILLY NAAN",
                                "ONION / GARLIC NAAN",
                                "STUFFED NAAN",
                                "HARIYALI NAAN",
                                "STUFFED PARATHA",
                                "MISSI ROTI",
                                "STUFFED KULCHA",
                                "ONION KULCHA",
                                "LACHADAR KULCHA",
                                "BUTTER KULCHA",
                                "PLAIN KULCHA",
                                "BUTTER PARATHA",
                                "PLAIN PARATHA",
                                "BUTTER NAAN",
                                "PLAIN NAAN",
                                "BUTTER ROTI",
                                "PLAIN ROTI",] },
    { name: "SNACKS", subItems: ["BREAD BUTTER",
                                "VEG. SANDWICH",
                                "ALU MUTTER SANDWICH",
                                "ALU MUTTER CHEESE SANDWICH",
                                "JAM SANDWICH",
                                "CHEESE SANDWICH",
                                "CHEESE JAM SANDWICH",
                                "FRENCH FRIES",
                                "PANEER PAKODA",
                                "CHEESE PAKODA",
                                "MIX PAKODA",
                                "VEG. CUTLET",] },
    { name: "MILKSHAKE", subItems: ["VANILLA MILKSHAKE",
                                "STRAWBERRY MILK SHAKE",
                                "CHOCOLATE MILK SHAKE",
                                "KESAR PISTA MILK SHAKE",
                                "COLD COFFEE (WITH ICE CREAM Rs. 30/-EXTRA)",] },
    { name: "ICE-CREAM", subItems: ["SP. CITY CORNER ICE CREAM",
                                "SP. ICE CREAM(BUTTER SCOTCH / KESAR PISTA / KAJU DRAKSH)",
                                "ICE CREAM - (REGULAR)(VANILLA/STRAWBERRY/CHOCOLATE/CHERRY BERRY)",
                                "GULAB JAMUN (3 PCS.)",] },
  ]; // List of available items and their sub-items
  export default availableItems;